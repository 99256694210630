import experimentsConfig from '@/config/experiments'

export async function setupExperiments(pageType, store, cookies) {
  const hasExperiments = experimentsConfig.activePageTypes.includes(pageType)

  if (hasExperiments) {
    const experimentsFetched = store.getters['experiments/experimentsFetched']
    const userId = cookies.get('_vwo_uuid_v2')

    if (!experimentsFetched && userId) {
      await store.dispatch('experiments/fetchExperiments', { userId })
    }
  }
}
