
import { defineComponent } from '@nuxtjs/composition-api'
import { getIndirectClientHeaders, seoHead } from '~/assets/seo'
import { setupBreadcrumbs } from '~/assets/craft'
import { setupExperiments } from '@/assets/experiments'

export default defineComponent({
  name: 'Fallback',
  nuxtI18n: {
    paths: { nl: '/*', en: '/*' },
  },
  async asyncData({ error, redirect, req, route, store, $cookies, $config }) {
    const [, globalSeo, page] = await Promise.all([
      store.dispatch('global/fetchNavigation'),
      store.dispatch('global/fetchSeo'),
      store.dispatch('page/fetchUri', { uri: decodeURI(route.fullPath), push: true, options: { headers: getIndirectClientHeaders({ req }) } }),
    ])

    if (!page) {
      return error({ statusCode: 404, message: 'Page not found' })
    }

    if (page._redirect_) {
      return redirect(page._redirect_.type, page._redirect_.to)
    }

    await setupExperiments(page.__typename, store, $cookies)

    const tixEnvMap = {
      contentOverview_education_Entry: 'education',
      page_education_Entry: 'education',
      production_education_Entry: 'education',
    }
    store.commit('tix/setTixEnv', tixEnvMap[page.__typename] || 'tix') // fallback to "normal" tix env

    // Perform initial fetch on some pages, later updates will happen whenever the search parameters are updated in the store
    switch (page.__typename) {
      case 'discoveryCollection_discoveryCollection_Entry':
        store.commit('discovery-overview/initialize', { keyword: '', ...route.query, categories: [page.title] })
        await Promise.all([store.dispatch('discovery-overview/fetchAllFacets'), store.dispatch('discovery-overview/fetch')])
        break

      case 'discoverySearchOverview_discoverySearchOverview_Entry':
        store.commit('discovery-overview/initialize', route.query)
        await Promise.all([store.dispatch('discovery-overview/fetchAllFacets'), store.dispatch('discovery-overview/fetch')])
        break

      case 'eventOverview_eventOverview_Entry':
        store.commit('event-overview/initialize', route.query)
        await Promise.all([
          store.dispatch('event-overview/fetchBanners'),
          store.dispatch('event-overview/fetchFallbackBanners'),
          store.dispatch('event-overview/fetchAllFacets'),
          store.dispatch('event-overview/fetch'),
        ])
        break

      case 'pressOverview_pressOverview_Entry':
        store.commit('press-overview/initialize', route.query)
        await store.dispatch('press-overview/fetch')
        break

      case 'search_search_Entry':
        store.commit('search/initialize', route.query)
        await store.dispatch('search/fetch')
        break

      case 'seriesOverview_seriesOverview_Entry':
        store.commit('series-overview/initialize', route.query)
        await Promise.all([
          store.dispatch('series-overview/fetchBanners'),
          store.dispatch('series-overview/fetchFallbackBanners'),
          store.dispatch('series-overview/fetchHighlightedOrganizer'),
          store.dispatch('series-overview/fetchAllFacets'),
          store.dispatch('series-overview/fetch'),
        ])
        break
    }

    const componentMap = {
      campaign_default_Entry: 'TemplateCampaignDetail',
      contentOverview_default_Entry: 'TemplateOverview',
      contentOverview_education_Entry: 'TemplateEducationOverview',
      discovery_discovery_Entry: 'TemplateDiscoveryDetail',
      discoveryCollection_discoveryCollection_Entry: 'TemplateDiscoveryCollectionDetail',
      discoveryOverview_discoveryOverview_Entry: 'TemplateDiscoveryOverview',
      discoverySearchOverview_discoverySearchOverview_Entry: 'TemplateDiscoverySearchOverview',
      educationProductionOverview_default_Entry: 'TemplateEducationProductionOverview',
      event_default_Entry: 'TemplateEventDetailV2',
      eventOverview_eventOverview_Entry: 'TemplateEventOverview',
      favoritePage_favoritePage_Entry: 'TemplateFavoritePage',
      page_education_Entry: 'TemplatePageDetail',
      page_page_Entry: 'TemplatePageDetail',
      press_default_Entry: 'TemplatePressDetail',
      pressOverview_pressOverview_Entry: 'TemplatePressOverview',
      production_education_Entry: 'TemplateEducationProductionDetail',
      recommendation_default_Entry: 'TemplateRecommendationDetail',
      recommendation_v2_Entry: 'TemplateRecommendationDetailV2',
      search_search_Entry: 'TemplateSearch',
      series_series_Entry: 'TemplateSeriesDetail',
      seriesOverview_seriesOverview_Entry: 'TemplateSeriesOverview',
    }

    // add prefix "discovery" for discovery detail with fallback to home for every other page type
    const breadcrumbPrefixMap = {
      discovery_discovery_Entry: [{ labelKey: 'discovery-overview', url: store.getters['global/discoveryOverview']?.url }],
      discoverySearchOverview_discoverySearchOverview_Entry: [{ labelKey: 'discovery-overview', url: store.getters['global/discoveryOverview']?.url }],
      event_default_Entry: [{ labelKey: 'event-overview', url: store.getters['global/eventOverview']?.url }],
      press_default_Entry: [{ labelKey: 'press-overview', url: store.getters['global/pressOverview']?.url }],
      series_series_Entry: [{ labelKey: 'series-overview', url: store.getters['global/seriesOverview']?.url }],
    }

    return {
      ...setupBreadcrumbs(page, breadcrumbPrefixMap[page.__typename] || []),
      componentMap,
      globalSeo,
      page,
    }
  },
  head() {
    return seoHead(this.page, this.globalSeo)
  },
})
